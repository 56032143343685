<script>
import VxDialogView from "@/components/vx/VxDialogView";
import EditNoteForm from "./EditNoteForm";
import { EDIT_NOTE } from "./NoteQuery.gql";

export default {
  name: "EditNoteDialog",
  components: {
    VxDialogView,
    EditNoteForm,
  },
  data() {
    return {
      task: {},
    };
  },
  computed: {},
  apollo: {
    note: {
      query: EDIT_NOTE,
      variables() {
        return {
          id: this.$route.params.noteId,
        };
      },
    },
  },
};
</script>

<template>
  <VxDialogView title="Edit this note">
    <EditNoteForm v-if="!!note" :note="note" />
    <v-row v-else class="justify-center">
      <v-progress-circular width="2" indeterminate />
    </v-row>
  </VxDialogView>
</template>

<style></style>
