<script>
import EmployeeSelect from "@/components/util/RaiEmployeeSelect";
import { mapGetters } from "vuex";
import { UPDATE_NOTE } from "@/graphql/note/mutations.gql";

import { format, isBefore, startOfDay, parseISO } from "date-fns";
import { hasGlobalLoaderAccess } from "@/mixins/ui";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { injectActiveEmployee } from "@/mixins/employee";
import RaiDatePicker from "@/components/util/RaiDatePicker";
import get from "lodash/get";
import NoteEditor from "@/components/daybook/NoteEditor";

export default {
  name: "EditNoteForm",
  components: {
    EmployeeSelect,
    RaiDatePicker,
    NoteEditor,
  },
  mixins: [hasGlobalLoaderAccess, hasSnackbarAccess, injectActiveEmployee],
  props: {
    note: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      body: null,
      isManagerOnly: false,
      loadingKey: "add-note",
      employee: null,
      businessDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["isTeamMode"]),
    ...mapGetters("bizdate", ["getBizDate"]),
    changes() {
      const employeeId =
        get(this.note, "employee.id") !== get(this.employee, "id");
      const body = get(this.note, "body") !== this.body;
      const businessDate = get(this.note, "businessDate") !== this.businessDate;
      const endDate = get(this.note, "endDate") !== this.endDate;
      const isManagerOnly =
        get(this.note, "isManagerOnly") !== this.isManagerOnly;

      return {
        employeeId,
        body,
        businessDate,
        endDate,
        isManagerOnly,
      };
    },
    disabled() {
      const changes = [
        this.activeEmployeeCan.editNoteAuthor && this.changes.employeeId,
        this.activeEmployeeCan.editNoteContent && this.changes.body,
        this.activeEmployeeCan.editNoteDates &&
          (this.changes.businessDate || this.changes.endDate),
        this.changes.isManagerOnly,
      ].filter((e) => e);
      return changes.length === 0;
    },
    computedEmployeeId() {
      if (!/\d+/.test(this.activeEmployeeId))
        return (this.employee && this.employee.id) || null;
      return (
        this.activeEmployeeId || (this.employee && this.employee.id) || null
      );
    },
    shouldShowEmployeeSelect() {
      return this.activeEmployeeCan.editNoteAuthor;
    },
    variables() {
      const input = {
        id: get(this.note, "id"),
      };

      if (this.changes.isManagerOnly) {
        input.isManagerOnly = this.isManagerOnly;
      }

      if (this.activeEmployeeCan.editNoteAuthor && this.changes.employeeId) {
        input.employeeId = this.employee.id;
      }

      if (this.activeEmployeeCan.editNoteContent && this.changes.body) {
        input.body = this.body;
      }

      if (this.activeEmployeeCan.editNoteDates) {
        if (this.changes.businessDate) {
          input.businessDate = this.businessDate;
        }
        if (this.changes.endDate) {
          input.endDate = this.endDate;
        }
      }

      return {
        input,
      };
    },
    // If startDate is before today, startDate cannot be earlier than original startDate
    // Otherwise, cannot be earlier than today
    startDateMinimum() {
      const today = startOfDay(new Date());
      const startDate = startOfDay(
        parseISO(get(this, "note.businessDate")) || today
      );
      const formatter = (val) => format(val, "yyyy-MM-dd");

      if (isBefore(startDate, today)) return formatter(startDate);

      return formatter(today);
    },
    // If endDate is in the past, it cannot be changed to earlier than the original endDate
    // Otherwise, cannot be earlier than startDate
    endDateMinimum() {
      const today = startOfDay(new Date());
      const startDate = startOfDay(
        parseISO(get(this, "note.businessDate")) || today
      );
      const endDate = startOfDay(parseISO(get(this, "note.endDate")) || today);
      const formatter = (val) => format(val, "yyyy-MM-dd");

      if (isBefore(endDate, today)) return formatter(endDate);
      return formatter(startDate);
    },
  },
  watch: {},
  mounted() {
    this.body = this.note.body;
    this.employee = this.note.employee;
    this.businessDate = format(parseISO(this.note.businessDate), "yyyy-MM-dd");
    this.endDate = format(parseISO(this.note.endDate), "yyyy-MM-dd");
    this.isManagerOnly = this.note.isManagerOnly;
  },
  methods: {
    updateNote() {
      this.loading(this.loadingKey);
      this.$apollo
        .mutate({
          mutation: UPDATE_NOTE,
          variables: this.variables,
        })
        .then(() => true)
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while updating this note: ${error}`,
          });
          return false;
        })
        .then((success) => {
          this.showSnackbar({
            text: "Note updated successfully",
          });

          if (success) {
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.loaded(this.loadingKey);
        });
    },
    reset() {
      this.body = null;
      this.isManagerOnly = false;
      this.businessDate = null;
      this.endDate = null;
      this.employee = null;
      this.loaded(this.loadingKey);
    },
  },
};
</script>

<template>
  <v-container>
    <v-form>
      <v-row
        v-if="shouldShowEmployeeSelect"
        align="baseline"
        class="mb-4"
        no-gutters
      >
        <span class="mr-4">Who is the author of this note?</span>
        <employee-select v-model="employee" :employees="employees" />
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <NoteEditor v-model="body" style="font-size: 15px" />
          <!-- <EmojiPickerMenu @select="addEmoji" /> -->
        </v-col>
      </v-row>
      <!-- Business date -->
      <v-row justify="start" class="mt-4" no-gutters>
        <v-col cols="6 mr-1">
          <RaiDatePicker
            v-model="businessDate"
            label="Start date"
            hint="The day this note will show up"
            persistent-hint
            :min="startDateMinimum"
            :disabled="!activeEmployeeCan.editNoteDates"
          />
        </v-col>
        <v-col>
          <RaiDatePicker
            v-model="endDate"
            label="End date"
            hint="The last day this note will show"
            persistent-hint
            :min="endDateMinimum"
            :disabled="!activeEmployeeCan.editNoteDates"
          />
        </v-col>
      </v-row>
      <v-checkbox
        v-model="isManagerOnly"
        class="mt-4"
        label="Manager only"
      ></v-checkbox>
    </v-form>
    <v-row class="mx-2 justify-end">
      <v-btn
        :disabled="disabled"
        :loading="isLoading(loadingKey)"
        text
        color="primary"
        @click="updateNote"
        v-text="`Update note`"
      />
    </v-row>
  </v-container>
</template>

<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.w-128 {
  width: 32rem;
}
.h-96 {
  height: 24rem;
}
.t-4 {
  top: 1rem;
}
.-r-64 {
  right: -16rem;
}

.emoji-invoker {
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}

.emojis:after {
  content: "";
  flex: auto;
}
</style>
